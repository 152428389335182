import "./components/carousel-slider/CarouselSlider";
import "./components/typewriter/TypeWriter";
import "./components/image-gallery/ImageGallery";
import "./components/image-grid/ImageGrid";
import "./components/video-player/VideoPlayer";
import "./components/pdf-flip-book/PdfFlipBook";
import "./components/pdf-viewer/PdfFrame";
import "./components/pdf-viewer/PdfPage";
import "./components/pdf-viewer/PdfViewer";
import { installCss } from "./core/installCss";

installCss("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free", "@6.5.2/css/all.min.css");
