import { sleep } from "./sleep";

const domReadyPromise = new Promise<void>((resolve, reject) => {

    if (document.readyState !== "loading") {
        setTimeout(resolve, 1);
        return;
    }

    const d = () => {
        document.removeEventListener("DOMContentLoaded", d);
        resolve();
    };
    document.addEventListener("DOMContentLoaded", d);
});

export const ComponentHelper = {

    waitForReady: async () => {

        await domReadyPromise;
        await sleep(10);
        
    }

}