import ScrollHelper from "../../core/ScrollHelper";
import { XNode } from "../../core/XNode";
import { styled } from "../../core/core";

    styled.css `

        display: grid !important;
        box-sizing: border-box;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto 1fr auto;
        position: relative;

        & > * {
        }


        & * {
            box-sizing: border-box;
        }

        &::part(left) {
            grid-row: 1 / span 3;
            grid-column: 1;
            font-size: 2rem;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            font: var(--fa-font-solid);
            font-size: 2rem;
            &::after {
                content: "\uf137"
            }
        }

        &::part(right) {
            grid-row: 1 / span 3;
            grid-column: 3;
            font-size: 2rem;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            font: var(--fa-font-solid);
            font-size: 2rem;
            &::after {
                content: "\uf138"
            }
        }

        &::part(canvas) {
            grid-row: 2 ;
            grid-column: 2;
            display: grid;
            position: relative;
            grid: repeat(1, 1fr) / auto-flow 100%;
            column-gap: 10px;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
        }

        &::part(child) {
            grid-row: 1;
        }

        &::part(indicator) {
            grid-row: 3 ;
            grid-column: 2;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            gap: 5px;
        }

        &::part(circle) {
            cursor: pointer;
            font-size: 0.6rem;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-color: currentColor;
            -webkit-text-stroke-width: 1px;
            &::before {
                content: "⚫";
            }
        }
        &::part(active)::before {
            content: "⚪";    
        }

    `.installGlobal("carousel-slider");

class CarouselSlider extends HTMLElement {

    current: HTMLElement;

    indicator: HTMLElement;
    canvas: HTMLElement;

    connectedCallback() {
        // prepare element....
        setTimeout(() => this.prepare(), 1);
    }

    prepare() {
        const children = Array.from(this.children) as HTMLElement[];
        const root = this.attachShadow({ mode: "open"})
        XNode.render(root, <div>
            <button
                event-click={() => this.moveLeft() }
                part="left"></button>
            <div part="canvas"></div>
            <div part="indicator"></div>
            <button
                event-click={() => this.moveRight() }
                part="right"></button>
        </div>);

        const indicator = root.querySelector(`[part="indicator"]`) as HTMLElement;
        this.indicator = indicator;
        const canvas = root.querySelector(`[part="canvas"]`) as HTMLElement;
        this.canvas = canvas;

        this.current = children[0];
        let i = 0;
        for (const iterator of children) {
            // canvas.appendChild(iterator);
            const child = this.ownerDocument.createElement("slot");
            const name = `child${i++}`;
            child.setAttribute("name", name);
            child.setAttribute("part", "child");
            canvas.appendChild(child);
            iterator.slot = name;
            const span = this.ownerDocument.createElement("span");
            span.setAttribute("part", "circle");
            indicator.appendChild(span);
            span.addEventListener("click", () => {
                this.current = iterator;
                this.updateStyle();
            });
        }
        this.updateStyle(false);
    }

    moveLeft() {
        const previous = this.current.previousElementSibling as HTMLElement;
        if (!previous) {
            return;
        }
        this.current = previous;
        this.updateStyle();
    }

    moveRight() {
        const next = this.current.nextElementSibling as HTMLElement;
        if (!next) {
            return;
        }
        this.current = next;
        this.updateStyle();
    }

    updateStyle(useNative = true) {
        ScrollHelper.bringIntoView(this.current, useNative);
        const index = Array.from(this.children).indexOf(this.current);
        Array.from(this.indicator.children).forEach((x, i) => {
            x.setAttribute("part", i === index
                ? "circle active"
                : "circle");
        });
    }

}

customElements.define("carousel-slider", CarouselSlider);
