import { ComponentHelper } from "../../core/ComponentHelper";
import ScrollHelper from "../../core/ScrollHelper";
import { XNode } from "../../core/XNode";
import { styled } from "../../core/core";

    styled.css `
        display: grid !important;
        box-sizing: border-box;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto 1fr 20%;
        position: relative;

        max-height: 90vh;

        & > * {
            height: 80vh;
            max-height: inherit;
            max-width: inherit;
            object-fit: contain;
        }

        & * {
            box-sizing: border-box;
        }

        &::part(left) {
            grid-row: 1 / span 3;
            grid-column: 1;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            box-sizing: border-box;
            margin: 10px;
            font: var(--fa-font-solid);
            font-size: 2rem;
            &::after {
                content: "\uf137"
            }

        }

        &::part(right) {
            grid-row: 1 / span 3;
            grid-column: 3;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            box-sizing: border-box;
            margin: 10px;
            font: var(--fa-font-solid);
            font-size: 2rem;
            &::after {
                content: "\uf138"
            }
        }

        &::part(canvas) {
            grid-row: 2 ;
            grid-column: 2;
            display: grid;
            position: relative;
            grid: repeat(1, 1fr) / auto-flow 100%;
            column-gap: 20rem;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
            box-sizing: border-box;
        }
        &::part(container) {
            display: block;
            text-align: center;
            box-sizing: border-box;
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
        }

        &::part(child) {
            grid-row: 1;
            padding: 10px;
            max-width: 100%;
            max-height: 100%;
            box-sizing: border-box;
        }

        &::part(indicator) {
            grid-row: 3 ;
            grid-column: 2;
            display: grid;
            /** Do not use this. */
            // justify-content: center;
            position: relative;
            grid: repeat(1, 1fr) / auto-flow auto;
            column-gap: 10px;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
            box-sizing: border-box;
            background-color: transparent;
        }

        &::part(thumbnail) {
            grid-row: 1;
            cursor: pointer;
            box-sizing: border-box;
            border: solid 5px transparent;
            background-color: transparent;
        }

        &::part(active) {
            border-color: var(--accent-color, gray);
            background-color: var(--accent-color, gray);
        }

        &::part(thumbnail-image) {
            box-sizing: border-box;
            max-height: 150px;
            text-align: center;
        }


    `.installGlobal("image-gallery");

const getThumbnail = (iterator: HTMLElement) => {
    if (iterator.tagName !== "IMG") {
        iterator = iterator.querySelector("img");
    }
    if(!iterator) {
        return;
    }
    let src = iterator.getAttribute("srcset");
    if (src) {
        src = src.split(" ")[0];
        return src;
    }
    return iterator.getAttribute("src");
}

class ImageGallery extends HTMLElement {

    current: HTMLElement;

    indicator: HTMLElement;
    canvas: HTMLElement;


    connectedCallback() {
        // prepare element....
        this.prepare().catch(console.error);
    }

    async prepare() {

        await ComponentHelper.waitForReady();

        const children = Array.from(this.children) as HTMLElement[];
        const root = this.attachShadow({ mode: "open"})
        XNode.render(root, <div>
            <button
                event-click={() => this.moveLeft() }
                part="left"></button>
            <div part="canvas"></div>
            <div part="indicator"></div>
            <button
                event-click={() => this.moveRight() }
                part="right"></button>
        </div>);

        const indicator = root.querySelector(`[part="indicator"]`) as HTMLElement;
        this.indicator = indicator;
        const canvas = root.querySelector(`[part="canvas"]`) as HTMLElement;
        this.canvas = canvas;

        this.current = children[0];
        let i = 0;
        for (const iterator of children) {
            const name = `child${i++}`;
            
            const child: XNode = <div part="container">
                <slot name={name} part="child"/>
            </div>;
            const img = child.appendTo(canvas);
            iterator.slot = name;
            const src = getThumbnail(iterator);
            XNode.render(indicator, <div>
                <div part="thumbnail" event-click={(e) => this.thumbnailClick(e, iterator)}>
                    <img part="thumbnail-image" src={src}/>
                </div>
            </div>);
        }
        setTimeout(() => this.updateStyle(false), 1000);
    }

    thumbnailClick(e: Event, img: HTMLElement) {
        this.current = img;
        this.updateStyle();
    }

    moveLeft() {
        const previous = this.current.previousElementSibling as HTMLElement;
        if (!previous) {
            return;
        }
        this.current = previous;
        this.updateStyle();
    }

    moveRight() {
        const next = this.current.nextElementSibling as HTMLElement;
        if (!next) {
            return;
        }
        this.current = next;
        this.updateStyle();
    }

    updateStyle(useNative = true) {
        ScrollHelper.bringIntoView(this.current, useNative);
        const index = Array.from(this.children).indexOf(this.current);
        Array.from(this.indicator.children).forEach((x, i) => {
            if (i === index) {
                setTimeout(() => ScrollHelper.bringIntoView(x as any, useNative), 1000);
            }
            x.setAttribute("part", i === index
                ? "thumbnail active"
                : "thumbnail");
        });
    }

}

customElements.define("image-gallery", ImageGallery);
